
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, OnDestroy, ElementRef, EventEmitter, ViewChild, Input, Output, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit } from "@angular/core";

@Component({
selector: "vp-vnctask-toolbar",
template: `
<div class="task-toolbar">
  <div class="task-toolbar-buttons">
    <button id="description_bold" mat-button class="mat-button" vpSuppressEvents [suppressEvents]="suppressEvents" (onClick)="applyTextStyle('bold');"><i class="material-icons" [class.active]="isBold">format_bold</i></button>
    <button id="description_italic" mat-button class="mat-button" vpSuppressEvents [suppressEvents]="suppressEvents" (onClick)="applyTextStyle('italic');"><i class="material-icons" [class.active]="isItalic">format_italic</i></button>
    <button id="description_underline" mat-button class="mat-button" vpSuppressEvents [suppressEvents]="suppressEvents" (onClick)="applyTextStyle('underline');"><i class="material-icons" [class.active]="isUnderline">format_underlined</i></button>
    <!-- <button #ignoredButton class="mat-button" (click)="openColorPicker()" [cpPositionOffset]="'-100%'" [cpIgnoredElements]="[ignoredButton]" [cpPositionRelativeToArrow]="'true'" [(cpToggle)]="colorToggle" [(colorPicker)]="colorPicker" [cpPosition]="cpPosition" [cpDisableInput]="'true'" [cpOutputFormat]="'hex'" (colorPickerChange)="colorPickerChange('foreColor', colorPicker);" (colorPickerClose)="colorPickerClose('foreColor', colorPicker);"><i class="material-icons" [ngStyle]="{'color': colorPicker ? colorPicker : '#000000' }">format_color_text</i></button> -->
    <button id="description_unorder" mat-button class="mat-button" vpSuppressEvents [suppressEvents]="suppressEvents" (onClick)="applyTextStyle('insertUnorderedList');"><i class="material-icons" [class.active]="isUnorderedList">format_list_bulleted</i></button>
    <button id="description_order" mat-button class="mat-button" vpSuppressEvents [suppressEvents]="suppressEvents" (onClick)="applyTextStyle('insertOrderedList');"><i class="material-icons" [class.active]="isOrderedList">format_list_numbered</i></button>
  </div>
  <div contenteditable="true" id="text-editor" title="{{ 'ENTER_DESCRIPTION' | translate }}" (click)="onClick()" (keydown)="onKeyDown($event)" (keyup)="onKeyUp($event)"></div>
</div>
`,
changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskToolbarComponent implements OnInit, AfterViewInit, OnDestroy {
textEditor: any;
isBold: boolean = false;
suppressEvents = null;
isItalic: boolean = false;
isUnderline: boolean = false;
isUnorderedList: boolean = false;
isOrderedList: boolean = false;
isToolbarView: boolean = false;
isToolbarClick: boolean = false;
// colorToggle: boolean = false;
@Input() content: any;
// colorPicker: any = null;
// cpPosition: any = "top";
@Output() isToolbarOpen = new EventEmitter<Boolean>();
keyCodes = {
  backspace: 8,
  delete: 46
};

constructor(private changerDetectorRef: ChangeDetectorRef) {
}

ngOnInit(): void {
  this.textEditor = document.querySelector("#text-editor");
  if (this.content) {
    this.textEditor.innerHTML = this.content;
  }
  let self = this;
  this.textEditor.onfocus = function() {
    self.isToolbarView = true;
    self.isToolbarOpen.emit(self.isToolbarView);
    self.changerDetectorRef.markForCheck();
  };

  this.textEditor.onblur = function() {
    setTimeout(() => {
      if (!self.isToolbarClick) {
        self.isToolbarView = false;
        self.changerDetectorRef.markForCheck();
        self.isToolbarOpen.emit(self.isToolbarView);
      }
      self.isToolbarClick = false;
    }, 300);

  };

  this.changerDetectorRef.markForCheck();
}

ngAfterViewInit() {
  setTimeout(() => {
    if (this.textEditor) {
      (this.textEditor as HTMLElement)?.focus();
    }
  }, 0);
  if (!this.content) {
    this.executeCmdWithArg("defaultParagraphSeparator", "p");
  }
}

applyTextStyle(command) {
  this.setFocus();
  this.executeCmd(command);
  this.getButtonState();
  this.isToolbarClick = true;
}

setFocus() {
  this.textEditor.focus();
  this.isToolbarView = true;
  this.changerDetectorRef.markForCheck();

}

executeCmd(command) {
  document.execCommand(command, false, null);
}

insertParagraph() {
  this.executeCmdWithArg("formatblock", "p");
}
/*colorPickerClose(command, arg) {
  this.setFocus();
  this.executeCmdWithArg(command, arg);
  this.getButtonState();
}

colorPickerChange(command, arg) {
  this.executeCmdWithArg(command, arg);
}*/

executeCmdWithArg(command, arg) {
  document.execCommand(command, false, arg);
}

executeCmdGetState(command) {
  return document.queryCommandState(command);
}

executeCmdGetValue(command) {
  return document.queryCommandValue(command);
}

getcontent() {
  return this.textEditor.innerHTML.replace(/\<br\>\<\/li\>/g, "\<\/li\>");
}

onKeyDown($event) {
  this.getButtonState();
}

onKeyUp($event) {
  if ($event.keyCode === this.keyCodes.backspace || $event.keyCode === this.keyCodes.delete) {
    if ( this.getcontent() === "" || this.getcontent() === "<br>") {
      this.resetButtons();
      this.insertParagraph();
    }
  }
}

getButtonState() {
  this.isBold = this.executeCmdGetState("bold");
  this.isItalic = this.executeCmdGetState("italic");
  this.isOrderedList = this.executeCmdGetState("insertOrderedList");
  this.isUnderline = this.executeCmdGetState("underline");
  this.isUnorderedList = this.executeCmdGetState("insertUnorderedList");
  // this.colorPicker = this.colorToHex(this.executeCmdGetValue("foreColor"));
  this.changerDetectorRef.markForCheck();
}

colorToHex(color) {
  if (color.substr(0, 1) === "#") {
      return color;
  }
  let rgb = color.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
  return (rgb && rgb.length === 4) ? "#" +
  ("0" + parseInt(rgb[1], 10).toString(16)).slice(-2) +
  ("0" + parseInt(rgb[2], 10).toString(16)).slice(-2) +
  ("0" + parseInt(rgb[3], 10).toString(16)).slice(-2) : "";
}

resetButtons() {
  if (this.executeCmdGetState("bold")) {
    this.executeCmd("bold");
  }
  if (this.executeCmdGetState("italic")){
    this.executeCmd("italic");
  }
  if (this.executeCmdGetState("underline")){
    this.executeCmd("underline");
  }
  if (this.executeCmdGetState("insertOrderedList")){
    this.executeCmd("insertOrderedList");
  }
  if (this.executeCmdGetState("insertUnorderedList")){
    this.executeCmd("insertUnorderedList");
  }
  // this.colorPicker = null;
  // this.executeCmdWithArg("foreColor", this.colorPicker);
  this.getButtonState();
  this.changerDetectorRef.markForCheck();
}

/*openColorPicker() {
  if (environment.isCordova) {
    Keyboard.hide();
  }
  setTimeout(() => {
    this.colorToggle = !this.colorToggle;
    this.changerDetectorRef.markForCheck();
  }, 100);
}*/

ngOnDestroy(): void {
}

onClick() {
  this.getButtonState();
  if (this.getcontent() === "") {
    this.insertParagraph();
  }
}

getContentInTextile() {
  return toTextile(this.getcontent());
}

}
